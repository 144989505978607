<template>
  <div>
    <div style="width: 100%">
      <img style="width: 100%" src="./img/顶部背景.png" alt="" />
    </div>
    <!-- 案例大模块 -->
    <div v-if="isshow" class="anlibigCss">
      <!-- 能源模块 -->
      <div class="anliDiv">
        <div class="biaotiCss">
          <img
            style="width: 36px; height: 36px; margin-top: 4%"
            src="./img/能源.png"
            alt=""
          />
          <div class="title">智慧能源</div>
          <div class="ENtitle">SMART ENERGY</div>
        </div>
        <div class="neirCss">
          <div
            class="box"
            v-for="(item, index) in this.nengyuanData"
            :key="index"
          >
            <img :src="item.content.dataList[0].url" alt="" class="box_img" />
            <div class="inside">
              <span
                class="word"
                style="
                  font-size: 32px;
                  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
                  font-weight: bold;
                  color: #ffffff;
                "
                >{{ item.content.dataList[0].title }}</span
              >
              <span>{{ item.content.dataList[0].content }}</span>
              <button class="xiangq" @click="go(item)">查看详情</button>
            </div>
          </div>
        </div>
      </div>
      <!-- 城市模块 -->
      <div class="anliDiv" style="margin-bottom: 88px;">
        <div class="biaotiCss">
          <img
            style="width: 36px; height: 36px; margin-top: 4%"
            src="./img/城市.png"
            alt=""
          />
          <div class="title">智慧城市</div>
          <div class="ENtitle">SMART CITIES</div>
        </div>
        <div class="neirCss">
          <div
            class="box"
            v-for="(item, index) in this.chengshiData"
            :key="index"
          >
            <img :src="item.content.dataList[0].url" alt="" class="box_img" />
            <div class="inside">
              <span
                class="word"
                style="
                  font-size: 32px;
                  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
                  font-weight: bold;
                  color: #ffffff;
                "
                >{{ item.content.dataList[0].title }}</span
              >
              <span>{{ item.content.dataList[0].content }}</span>
              <button class="xiangq" @click="go(item)">查看详情</button>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- 内容页 -->
    <div>
      <div style="background: #f2f5f8; padding-top: 64px">
        <div
          style="
            width: 1314px;
            margin-left: auto;
            margin-right: auto;
            background: #ffffff;
          "
          class="ql-snow"
          v-if="!isshow"
        >
          <div
            style="
              padding-top: 54px;
              font-size: 38px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #000000;
              text-align: center;
            "
          >
            {{ this.editorText.content.dataList[0].title }}
          </div>
          <!-- <div
            style="
              font-size: 18px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.4);
              padding-top: 16px;
              text-align: center;
            "
          >
            {{ this.editorText.content.dataList[0].time }}
          </div> -->
          <div
            class="ql-editor"
            id="editorText"
            v-html="this.editorText.content.dataList[0].editorText"
          ></div>
        </div>
      </div>

      <div style="position: fixed; right: 10px; bottom: 144px">
      <div v-if="!isshow" @click="fanhui" class="fhsj">
        <div class="fanhui">
          <img src="@/assets/返回上级蓝.png" alt="" />
        </div>
        <div>返回上级</div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import {
  getContents,
} from "@/api/index.js";
export default {
  data() {
    return {
      isshow: true,
      editorText: "",
      nengyuanData: [
        {
          url: require("./img/新能源发电.png"),
          title: "新能源发电解决方案",
          content:
            "公司提供分布式光伏电站、集中式光伏电站、风光互补电站、风力发电站等新能源发电项目可研、方案、设计、设备选型采购、项目管...",
            router: "/newEnergy"
        },
        {
          url: require("./img/微电网.png"),
          title: "微电网解决方案",
          content:
            "公司依托自身在微电网领域十多年的技术基础及行业应用，针对产业园区、高校、工业企业、偏远地区等地区，提供微电网整体解决...",
            router: "/microgrid"
        },
        {
          url: require("./img/储能.png"),
          title: "储能解决方案",
          content:
            "公司结合自身在新能源领域十多年的技术基础及行业应用，为客户提供储能整体解决方案，包括发电侧储能解决方案、电网侧储能解决...",
            router: "/StoredEnergy"
        },
      ],
      chengshiData: [
        {
          url: require("./img/城市静态交通.png"),
          title: "城市静态交通解决方案",
          content:
            "城市静态交通解决方案将行业经验与前沿技术完美融合，为城市静态交通治理提供“投、融、建、管、运”五位一体支撑，助力城市...",
            router: "/chengshijt"
        },
        {
          url: require("./img/城市交管.png"),
          title: "城市交管解决方案",
          content:
            "城市交管解决方案通过将先进的信息管理、数据通讯传输、电子传感、控制及计算机等技术有效的集成运用于整个地面交通管理系统...",
            router: "/jiaoguan"
        },
        {
          url: require("./img/智慧高速.png"),
          title: "智慧高速交通解决方案",
          content:
            "智慧高速交通解决方案基于全数字网络，通过在高速公路道路、隧道、收费站、服务区等区域安装高清摄像机及LED诱导屏，对采集到...",
            router: "/gaoshu"
        },
      ],
    };
  },
  methods:{
    go(index){
      // this.$router.push(index.router);
      this.isshow = !this.isshow;
      this.editorText = index;
    },

    async getList() {
      let params = {
        menuId: "2038",
        id: "",
      };
      const res = await getContents(params);
      for (let index = 0; index < res.length; index++) {
        // const zj = JSON.parse(res[0].content);
        res[index].content = JSON.parse(res[index].content);
      }
      // const zj = JSON.parse(res[0].content);
      console.log(res, "ress");
      this.nengyuanData = res;


      let params2 = {
        menuId: "2039",
        id: "",
      };
      const res2 = await getContents(params2);
      for (let index = 0; index < res2.length; index++) {
        // const zj = JSON.parse(res[0].content);
        res2[index].content = JSON.parse(res2[index].content);
      }
      // const zj = JSON.parse(res[0].content);
      this.chengshiData = res2;
    },
    fanhui(){
      this.isshow=!this.isshow
    },
  },
  mounted(){
    this.getList()
  }
};
</script>

<style scoped>
::v-deep.el-backtop {
  width: 82px;
  height: 82px;
}
.fhsj {
  cursor: pointer;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  /* text-align: center; */
  width: 82px;
  height: 82px;
  background: #ffffff;
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.fhsj:hover {
  cursor: pointer;
  background: #0940fd;
  color: rgba(255, 255, 255, 0.6);
  .fanhui img {
    content: url("../../assets/返回上级.png");
  }
}

.anlibigCss {
  width: 100%;
}
.anliDiv {
  margin-left: 15.5%;
  margin-right: 15.5%;
  margin-bottom: 1%;
}
.biaotiCss {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  margin-top: 1%;
  margin-bottom: 1%;
  font-size: 30px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.9);
}
.ENtitle {
  font-size: 28px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.05);
}
.box {
  position: relative;
  width: 426px;
  height: 476px;
  overflow: hidden;
  margin-top: 24px;
}
.box_img {
  width: 426px;
  height: 476px;
  position: absolute;
  transition: all 0.5s;
}
.box:hover .box_img {
  transform: scale(1.05);
  transition: all 0.5s;
}
.inside {
  overflow: hidden;
  width: 426px;
  height: 476px;
  position: absolute;
  background: linear-gradient(
    360deg,
    rgba(0, 36, 64, 0.6) 15%,
    rgba(9, 115, 253, 0) 100%
  );
  transition: all 0.5s;
  letter-spacing: 1px;
  /* color: transparent; */
}
.inside:hover {
  background: linear-gradient(360deg, #002440 0%, rgba(9, 115, 253, 0) 100%);
  transition: all 0.5s;
}
.inside span {
  margin-left: 32px;
  margin-right: 32px;
  display: block;
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 38px;
  /* 最多四行 */
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.word {
  margin-top: 290px;
  transition: all 0.5s;
  margin-bottom: 16px;
}
.box:hover .word {
  margin-top: 180px;
  transition: all 0.5s;
}
.xiangq {
  display: none;
  margin-left: 32px;
  margin-top: 24px;
  width: 138px;
  height: 58px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #ffffff;
  font-size: 20px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.xiangq:hover {
  background: rgba(0, 0, 0, 0.4);
}
.neirCss {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>